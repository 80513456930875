.list-insight-header {
  background-color: #ffffff;
  height: auto;
  padding: 2%;
}

.search-insight {
  background-color: #ffffff;
  height: auto;
  padding: 2%;
  margin: 2%;

  .ant-space-item:nth-child(2) {
    width: 100%;
  }

  .button-search {
    .ant-space-item:nth-child(1),
    .ant-space-item:nth-child(2) {
      width: 100%;
    }
  }
}

.list-insight-container {
  background-color: #ffffff;
  height: auto;
  padding: 2%;
  margin: 2%;
}
.insight-title-column {
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
