@import "./colors";

body,
.App {
  min-height: 100vh;
  background-color: $c1;
}

.yb {
  &-logo {
    height: 45px;
    margin: 10px 5px 5px 5px;
  }

  &-c2 {
    color: $c2;
  }

  &-c-white {
    color: $c-white;
  }

  &-input,
  &-btn {
    height: 50px;
  }

  &-w-100 {
    width: 100%;
  }

  &-fz-1 {
    font-size: 28px;
    letter-spacing: -1px;
  }
}

@import "./auth";

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.hide-resize-observer {
  display: none !important;
}

.ant-tooltip-inner {
  width: 100px;
}

.upload-image-components {
  .ant-upload-list {
    display: flex;
  }
  .ant-upload-picture-card-wrapper {
    width: 100% !important;
  }
}
