.login-page {
  .login-left {
    width: calc(100% - 460px);
    height: 100vh;
    background-image: url("../../assets/images/ephr-logo.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-right {
    width: 100%;
    max-width: 460px;
    height: 100%;
    padding: 0 2rem;
    margin-top: 166px;

    h1 {
      font-size: 48px;
      font-family: "Roboto", sans-serif;
      margin: 0 0 60px;
    }

    .input-pass {
      position: relative;

      input {
        padding-right: 2.5rem;
      }

      .btn-showPass {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
