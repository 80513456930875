.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 2px 0 0 2px !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 7px 7px 0 !important;
}
