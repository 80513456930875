.dashboard-firstline-item-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 12px;
  text-align: left;
}

.dashboard_firstline_item_value {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 36px;
  text-align: left;
}
